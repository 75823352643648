import React  from "react";
import {Link } from "react-router-dom";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import Banner from "../common/banner/banner";
import bg from "../../images/bg/03.jpg";



function About(props) {
    return   (
        <>
            <PageName page={"About Us"} />
            <Banner
                Image={bg}
                Title={"About Us"}
                breadcrumb={["Home", "About Us"]}
            />
            <div className="academics-details-area pt-4 mb-5 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="academics-details">
                                <div className="details">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:  props.homeData.AboutContent,
                                        }} style={{textAlign: 'justify'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                            <div className="course-sidebar shadow">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Additional Information</h5>
                                    </div>
                                    <div className="widget-categories">
                                        <ul className="list-unstyled list-style mb-0">
                                            <li>
                                                <Link to={`/our-campus`}>
                                                        Our Campus
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admission/course-finde`}>
                                                    Courses
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admission/application`}>
                                                    Application
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/education`}>
                                                    Education
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/news`}>
                                                    News
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(About);