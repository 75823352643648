import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NewsDetailsContent from "./section/newsdetailscontent";
import {
  setGeneralDetails,
  setPublicationDetails,
} from "../../../actions/setactiondetails";
import bg3 from "../../../images/bg/03.jpg";
import Banner from "../../common/banner/banner";

function NewsDetails(props) {
  const [newsData, setNewsData] = useState(props.newsData);
  const [newsList, setNewsList] = useState(props.newsList);
  const [change, setChange] = useState("");

  useEffect(() => {
    setNewsData(props.newsData);
  }, [change]);
  const changeScreen = (items) => {
    setChange(items);
  };

  return (
    <>
      <Banner
        Image={bg3}
        Title={"News Details"}
        breadcrumb={["Home", "News Details"]}
      />

      <section className="space-ptb" />
      <div className="news-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <NewsDetailsContent newsData={newsData} IsNews={props.IsNews} newsList={newsList}
              setGeneralDetails={props.setOnGeneralDetails}
              channgeScreen={changeScreen}
               />

          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsData: state.GeneralDetails,
    newsList: state.NewsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
