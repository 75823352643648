import React from "react";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/09.jpg";
import { Link, useParams } from "react-router-dom";
import { projectName } from "../../../resources/url";
import { connect } from "react-redux";


const FeeDetails = (props) => {
    const params = useParams()
    const slug = params.slug


    return (
        <>
            <Banner
                Image={bg3}
                Title={slug === "tuition" ? "Tuition Fees" : slug === "accommodation" ? "Accommodation" : "Feeding"}
                breadcrumb={["Admissions", "Fees Details"]}
                height="50px"
            />
            <section className="space-pb pt-5">
                <div className="container">
                    <div className="row">
                        {
                            slug === "tuition" &&
                            <div className="col-9">
                                <div>
                                    <h5 className="text-primary mt-4">Tuition Cost</h5>
                                    <p className="mb-4 text-justify">Currently there is no distinction between the tuition cost for Domestic students (students of Nigerian nationality) and International students.</p>
                                    <div className="table table-responsive w-100">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <td colSpan={2}><h6>Undergraduate</h6></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.FacultyDetails.length > 0 &&
                                                    props.FacultyDetails.map((x, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{x.FacultyName}</td>
                                                                <td>N2,722,500* (2 Semesters)</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <div>
                                    <h5 className="text-primary mt-4">Tuition Payment</h5>
                                    <p className="mb-4 text-justify">Depending on the student's payment preference, the tuition fee can either be paid per semester or per academic year. If the student chooses the per semester payment method but is unable to provide the complete required funds, he or she can pay in installments. However, complete payment of tuition is required before the commencement of the exam period.</p>
                                </div>

                                <div>
                                    <h5 className="text-primary mt-4">Tuition Revision</h5>
                                    <p className="mb-4 text-justify">The tuition cost is subject to revisions by the Senate of the university. This tuition change can be as a result of economic situations in the country or introduction of new systems that will lead to a more efficient learning environment. All students and parents/guardians will be informed of any new development regarding the tuition cost.</p>
                                </div>
                                <div>
                                    <h5 className="text-primary mt-4">Tuition and Admission</h5>
                                    <p className="mb-4 text-justify">Getting an admission at the university does not guarantee a place unless the tuition is paid. Thus, for incoming or transfer students, paying the tuition is part of the acceptance process into the university. The tuition cost only covers the cost of courses undertaken for the semester.</p>
                                </div>
                                <div>
                                    <h5 className="text-primary mt-4">Scholarship</h5>
                                    <p className="mb-4 text-justify">The university currently has no scholarship opportunities for students.</p>
                                </div>
                            </div>
                        }

                        {
                            slug === "accommodation" &&
                            <div className="col-9">
                                <div>
                                    <h5 className="text-primary mt-4">Accommodation</h5>
                                    <p className="mb-4 text-justify">Students can either use accommodation provided by the University, live at home, or arrange their own accommodation. In terms of proximity and ease of access to university resources, the campus accommodation is the preferable option due to the location of the university.</p>
                                </div>

                                <div>
                                    <h5 className="text-primary text-justify mt-4">Campus Accommodation and Cost</h5>
                                    <p className="mb-4 text-justify">Providing a space that serves as a home away from home is our priority at {projectName}. Our campus accommodation options are designed with the utmost consideration for the students’ welfare. Students will be housed in either one-person, or two-person per rooms hostel accommodation. <br /><br />
                                    Some limited two bedroom block of flats in old G.R.A Maiduguri are available to be rented out as accommodation for interested students. Candidates are therefore requested to contact the Dean of Students for details.</p>
                                </div>
                            </div>
                        }
                        {
                            slug === "feeding" &&
                            <div className="col-9">
                                <div>
                                    <h5 className="text-primary mt-4">Feeding</h5>
                                    <p className="mb-4 text-justify">The meals arrangement is solely-based on the students’ preference. The school however has made a provision for students and parents that want to regulate the feeding cost in an effective manner. Students can pay a certain amount (there are no limitations) and be given a feeding booklet. Using the slips in this booklet, they can buy food from the eating areas that use this method as a form of payment. This reduces the need to carry around cash whilst ensuring that students are properly fed and satisfied. Currently, the only cafeterias that use this form of transaction is Brim and Terminal 2</p>
                                </div>
                            </div>
                        }
                        <div className="col-lg-3">
                            <div className="course-sidebar shadow mt-5">
                                <div className="widget">
                                    <div className="widget-categories">
                                        <ul className="list-unstyled list-style mb-0">
                                            <strong>Quick Links</strong>
                                            <hr />
                                            <li>
                                                <Link className="d-flex" to={`/fees`}>
                                                    Fees
                                                    <i className="fas fa-chevron-right text-right ml-auto" />
                                                </Link>

                                            </li>
                                            <hr />
                                            <li>
                                                <Link className="d-flex" to={`/course-finder`}>
                                                    Course Finder
                                                    <i className="fas fa-chevron-right text-right ml-auto" />
                                                </Link>

                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>

    )
}


const mapStateToProps = ((state) => {
    return ({
        FacultyDetails: state.FacultyListDetails
    })
})
export default connect(mapStateToProps, null)(FeeDetails);