import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bg3 from "../../../../images/bg/03.jpg";
import { serverLink } from "../../../../resources/url";
import { encryptData } from "../../../../resources/url";
import Banner from "../../../common/banner/banner";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";


const ApplicationRecoverPassword = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const token = window.location.href.split('/')[6]
    const [login, setLogin] = useState({
        Password: "",
        cPassword: "",
        EmailAddress: ""
    });



    const ValidateToken = async () => {
        await axios
            .get(`${serverLink}application/forget_password/validate_token/${token}`)
            .then((res) => {
                if (res.data.length === 0) {
                    navigate('/')
                } else {
                    setLogin({
                        ...login,
                        EmailAddress: res.data[0].EmailAddress
                    })
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() => {
        ValidateToken();
    }, []);


    const handleReset = async (e) => {
        e.preventDefault();
        if (login.Password !== login.cPassword) {
            toast.error('Password does not match');
            return;
        }

        const formData = {
            Password: encryptData(login.Password),
            EmailAddress: login.EmailAddress
        }

        await axios.patch(`${serverLink}application/forget_password/change_password/${token}`, formData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success('Your password have been changed successfully.');
                    navigate('/admission/application/login')
                } else {
                    toast.error('Please try again.')
                }
            }).catch((e) => {
                toast.error('Please try again.')
                console.log('NETWORK ERROR')
            })

    }

    const onEdit = (e) => {
        setLogin({
            ...login,
            [e.target.id]: e.target.value
        })
    };

    return (
        <>
            <Banner
                Image={bg3}
                Title={"Recover Password"}
                breadcrumb={["Admission", "Recover Password"]}
                height="50px"
            />
            <section className="space-ptb">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                                <h3>Reset Password</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2">
                            <form className="login bg-white shadow p-5" onSubmit={handleReset}>
                                <div className="form-group">
                                    <label>Email address</label>
                                    <div className="form-group">
                                        <label htmlFor="Password">Email Address</label>
                                        <input
                                            type="password"
                                            id="Password"
                                            className="form-control"
                                            placeholder="Password*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                    <span className="focus-border" />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <div className="form-group">
                                        <label htmlFor="cPassword">Confirm Password</label>
                                        <input
                                            type="password"
                                            id="cPassword"
                                            className="form-control"
                                            placeholder="Confirm Password*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                    <span className="focus-border" />
                                </div>

                                <button type="submit" className="default-btn btn active">
                                    Change Password
                                </button>

                                <Link to="/admission/application/login">
                                    Login
                                </Link>
                                <br />

                            </form>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )


}

const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRecoverPassword);