import React from "react";

const JobOpeningsSide=(props)=>{
    let unique = [...new Set(props.byFaculty.map(x=>x.Faculty))];
    
    return(
        <div className="course-sidebar shadow">
        <div className="widget">
            <div className="widget-title">
                <h5>Categories</h5>
            </div>
            <div className="widget-categories">
                <ul className="list-unstyled list-style mb-0">
                    <li
                        onClick={props.setAllJobs}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        All({props.jobList2.length})
                    </li>
                    {props.byFaculty.length > 0 &&
                        unique.map((item, index) => {
                            const faculty_name_array = props.FacultyList.filter(
                                (x) => x.FacultyCode === item
                            );
                            const count = props.byFaculty.filter(x=>x.Faculty === item)
                            return (
                                <li
                                    key={index} style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }} onClick={() => props.setJobsByCategories(item)}
                                >
                                    {faculty_name_array.length > 0
                                        ? faculty_name_array[0].FacultyName
                                        : item}
                                    ({count.length})
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    </div>
    )
}

export default JobOpeningsSide;