import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {projectName, serverLink } from "../../../../resources/url";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setApplicationLoginDetails, setGeneralDetails } from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import Sidebar from "./sidebar";
import ExamType from "./ExamType";
import UGExamResult from "./ExamResult";
import Loader from "../../../common/loader/loader";

function Olevel(props) {
    const [userData] = useState(props.applicationLogin);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [resultData, setResultData] = useState([]);
    const [numOfSitting, setNumOfSitting] = useState(0);
    const [examType, setExamType] = useState({
        ExamType1: "",
        ExamCentre1: "",
        ExamYear1: "",
        ExamsNumber1: "",
        ExamType2: "",
        ExamCentre2: "",
        ExamYear2: "",
        ExamsNumber2: "",
    });
    const [examScore, setExamScore] = useState([
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
        { result_type: "", result_subject: "", result_score: "" },
    ]);
    const [shouldSave, setShouldSave] = useState(false);

    useEffect(() => {
        console.log(props.applicationData)
        getRecordData();

    }, []);

    const getRecordData = async () => {
        await axios
            .get(
                `${serverLink}application/ug/olevel/data/${props.applicationData.EntryID}`
            )
            .then((result) => {
                setResultData(result.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR");
            });
    };
    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        if (id === "NumberOfSitting") {
            setNumOfSitting(parseInt(value));
        }

        if (id.includes("Exam")) {
            setExamType({
                ...examType,
                [id]: value,
            });
        }

        if (id.includes("result_")) {
            let index_value = e.target.getAttribute("data");

            setExamScore(
                [...examScore].map((item, index) => {
                    if (index === parseInt(index_value)) {
                        return {
                            ...item,
                            [id]: value,
                        };
                    } else {
                        return item;
                    }
                })
            );
        }

        setShouldSave(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (examType.ExamType1 === "") {
            toast.error("Please Select Exam Type 1");
            return false;
        }

        if (
            examType.ExamType1 !== "" &&
            (examType.ExamCentre1 === "" ||
                examType.ExamYear1 === "" ||
                examType.ExamsNumber1 === "")
        ) {
            toast.error("Please Fill the Exam Type 1 Options, please");
            return false;
        }

        if (numOfSitting === 2) {
            if (examType.ExamType2 === "") {
                toast.error("Please Select Exam Type 2");
                return false;
            }

            if (
                examType.ExamType2 !== "" &&
                (examType.ExamCentre2 === "" ||
                    examType.ExamYear2 === "" ||
                    examType.ExamsNumber2 === "")
            ) {
                toast.error("Please Fill the Exam Type 2 Options, please");
                return false;
            }
        }

        let error_check = false;
        examScore.map((score, index) => {
            if (index < 5) {
                if (score.result_score === "" || score.result_type === "" || score.result_subject === "") {
                    toast.error(`Please fill all the result options in line ${index + 1}`);
                    error_check = true;
                    return false;
                }
            }
        });

        //CHECK IF APPLICANT SELECTED SIMILAR SUBJECT
        const unique = [];
        const duplicates = examScore.filter(o => {
            if (unique.find(i => i.result_type === o.result_type && i.result_subject === o.result_subject && o.result_subject !== '')) {
                return true
            }
            unique.push(o)
            return false;
        })
        if(duplicates.length) {
            toast.error(`You can't select similar subject from the same exam type`);
            return false;
        }

        if (error_check)
        {
            return false;
        }

        if (shouldSave) {
            toast.info(`Submitting... Please wait!`);

            //props.applicationData.EntryID;

            const sendData = {
                app_id: props.applicationData.EntryID,
                sitting: examType,
                score: examScore,
            };

            await axios
                .post(`${serverLink}application/olevel/result`, sendData)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success(`O level Result Added Successfully!`);
                        onNext();
                    } else
                        toast.error(
                            `Something went wrong. Please check your connection and try again!`
                        );
                })
                .catch((error) => {
                    console.log("NETWORK ERROR", error);
                });
        } else onNext();
    };

    const handleNewEntry = async () => {
        await axios
            .delete(
                `${serverLink}application/olevel/delete/${props.applicationData.EntryID}`
            )
            .then((res) => {
                if (res.data.message === "success") {
                    getRecordData();
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    const onPrevious = () => {
        navigate("/admission/application/undergraduate/personal-information");
    };

    const onNext = () => {
        navigate("/admission/application/undergraduate/jamb-result");
    };

    return loading ? (
        <Loader />
    ) : (
        <>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 text-center">
                        <div className="section-title">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Sidebar userData={userData} />
                    <div className="col-lg-9">
                        <form className="login bg-white  shadow p-5 mb-6" onSubmit={handleSubmit}>
                            <div className="register">
                                <h3>
                                    <>O'Level Results</>
                                </h3>
                                {resultData.sitting.length > 0 ?  (
                                    <>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                        <span
                            className="btn btn-dark btn-lg float-end"
                            onClick={handleNewEntry}
                        >
                         {
                             projectName.toLocaleLowerCase().includes('olivia') ?
                                 <> + Add New O/A'Level</>
                                 :<> + Add New O'Level</>
                         }
                        </span>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-responsive table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Exam Type</th>
                                                    <th>Exam Centre</th>
                                                    <th>Exam Number</th>
                                                    <th>Exam Year</th>
                                                    <th>Exam Scores</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resultData.sitting.map((sitting, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{sitting.ExamsType}</td>
                                                            <td>{sitting.ExamsCenter}</td>
                                                            <td>{sitting.ExamsNumber}</td>
                                                            <td>{sitting.ExaminationYear}</td>
                                                            <td>
                                                                {resultData.entry.length > 0 ? (
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                        {resultData.entry
                                                                            .filter(
                                                                                (en) =>
                                                                                    en.SittingID === sitting.EntryID
                                                                            )
                                                                            .map((entry, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{entry.ExamsSubject}</td>
                                                                                        <td>{entry.Score}</td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    "--"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-12 mt-3 mb-2">
                                            <div className="filters-group mb-lg-4 text-center">
                                                <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                                <button className="btn btn-dark w-50  active"  onClick={onNext}>  Save and Continue</button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="row w-100 mt-3 mb-2">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="NumberOfSitting">
                                                            Number of Sittings{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            id="NumberOfSitting"
                                                            name="NumberOfSitting"
                                                            className="form-control"
                                                            value={numOfSitting}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="0">Select Option</option>
                                                            <option value="1"> 1</option>
                                                            <option value="2"> 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {numOfSitting === 1 && (
                                                <ExamType
                                                    data={examType}
                                                    index={1}
                                                    onChange={handleChange}
                                                />
                                            )}

                                            {numOfSitting === 2 && (
                                                <>
                                                    <ExamType
                                                        data={examType}
                                                        index={1}
                                                        onChange={handleChange}
                                                    />
                                                    <ExamType
                                                        data={examType}
                                                        index={2}
                                                        onChange={handleChange}
                                                    />
                                                </>
                                            )}

                                            <div className="row mb-2 mt-3">
                                                <div className="col-md-12">
                                                    <h5>Results</h5>
                                                </div>
                                            </div>

                                            {examType.ExamType1 !== "" &&
                                            Array.from(Array(9), (e, l) => {
                                                return (
                                                    <UGExamResult
                                                        key={l}
                                                        exam_type={examType}
                                                        index={l}
                                                        handleChange={handleChange}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="col-md-12 mt-3 mb-2">
                                            <div className="filters-group mb-lg-4 text-center">
                                                <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                                <button className="btn btn-dark w-50  active" type="submit">  Save and Continue</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </form>
                        {/* )} */}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicant_data: state.GeneralDetails,
        applicationData: state.ApplicationDataDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Olevel);

