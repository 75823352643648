import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setApplicationLoginDetails, setApplicationRequirementDetails, setGeneralDetails } from "../../../../actions/setactiondetails";
import { serverLink } from "../../../../resources/url";
import Sidebar from "./sidebar";


const UndergraduateApplicationDashboard = (props) => {
    const navigate = useNavigate();
    const [userData] = useState(props.applicationLogin);
    const [requirementList, setrequirementList] = useState([]);
    const [applicationRequirement, setApplicationRequirement] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [applicationDetail, setApplicationDetail] = useState({
        oLevel: [],
        jamb: [],
        post_secondary: [],
        guardian: [],
        document: [],
    });

    const getUGApplicationRequirementList = async () => {
        await axios
            .get(`${serverLink}application/ug/requirements/`)
            .then((response) => {
                props.setApplicationRequirement(response.data.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        getUGApplicationRequirementList();
    }, []);

    useEffect(() => {
        async function applicationRequirementList() {
            axios
                .get(`${serverLink}application/documents/uploads`)
                .then((response) => {
                    setrequirementList(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        applicationRequirementList();
    }, []);

    useEffect(() => {
        async function allApplicationData() {
            axios
                .get(
                    `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
                )
                .then((response) => {
                    setApplicationDetail(response.data);
                    props.setOnGeneralDetails(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        allApplicationData();
    }, []);
    const handleLogout = () => {
        props.setOnApplicationLoginDetails("");
        props.setOnApplicationDataDetails("");
    };


    return (
        <>
            <div className="container">
                <div className="row">
                    <Sidebar userData={userData} />
                    <div className="col-lg-9">
                        {/* {guardianData.length < 1 && ( */}
                        <form className="login bg-white shadow p-5" >
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <h3>Dashboard</h3>
                                    <p>
                                        <strong>
                                            Note: You can start filling the application by clicking
                                            {"  "}
                                        </strong>
                                        on{"  "}
                                        <span style={{ color: "green" }}>
                                            Start Application
                                        </span>{" "}
                                        button below
                                    </p>
                                    <p>
                                        You can re-enter and complete your application(s) at any
                                        time convenient to you. Please fill in the application
                                        details using guidance on the screens, you will see ‘next’
                                        and ‘previous’ buttons to help you navigate your way around.
                                    </p>
                                    <ul>
                                        {requirementList.map((requirement, index) => (
                                            <li key={index}>{requirement.DocumentName}</li>
                                        ))}
                                    </ul>
                                    <p>
                                        Before you submit your application you can view your
                                        application in the application summary page.
                                    </p>
                                    <p>
                                        Before you submit your application you will be asked to
                                        confirm by declaration that the data you are submitting is
                                        correct.
                                    </p>
                                    <div className="col-lg-6 col-sm-6" style={{ float: "right" }}>
                                        <Link
                                            to={"/admission/application/undergraduate/selection"}
                                            className="btn btn-danger active w-100 active"
                                        >
                                            Start Application
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* )} */}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicationData: state.ApplicationDataDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setApplicationRequirement: (p) => {
            dispatch(setApplicationRequirementDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UndergraduateApplicationDashboard);


;