import React from "react";
import { connect } from "react-redux";
import { formatDateAndTime, serverLink } from "../../../../resources/url";
import SideSearch from "./sidebar";
import {
    setGeneralDetails,
} from "../../../../actions/setactiondetails";
function NewsDetailsContent(props) {
    return <section className="" style={{ marginTop: "-120px" }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 mb-5 mb-lg-0">
                    <SideSearch
                        newsList={props.newsList}
                        setGeneralDetails={props.setOnGeneralDetails}
                        channgeScreen={props.changeScreen}
                    />
                </div>
                <div className="col-lg-8 mb-5">
                    <div className="events">
                        <div className="events-img">
                            <img className="img-fluid border-0" src={ props.newsData.ImagePath !== null ? props.newsData.ImagePath.includes("simplefileupload") ? props.newsData.ImagePath : `${serverLink}public/uploads/news_uploads/${props.newsData.ImagePath}` : ""} alt="" />
                        </div>
                        <div className="events-content mt-4">
                            <div className="events-tag">
                                <a href="#" className="badge-danger mb-2">{props.newsData.FacultyCode}</a>
                            </div>
                            <p className="text-dark h5">{props.newsData.Title}</p>
                            <div className="events-meta my-2">
                                <ul className="list-unstyled mb-0 d-flex">
                                    <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(props.newsData.InsertedDate, "date")}</a></li>
                                    <li><a href="#"><i className="fas fa-clock pr-1"></i> {props.newsData.FacultyCode}</a></li>
                                </ul>
                            </div>
                            <span dangerouslySetInnerHTML={{ __html: props.newsData.Description }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

}

const mapStateToProps = (state) => {
    return {
      newsData: state.GeneralDetails,
      newsList: state.NewsDetails,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setOnGeneralDetails: (p) => {
        dispatch(setGeneralDetails(p));
      },
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsContent);

