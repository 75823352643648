import React from "react";

function CounterSection() {
    return (
        <section className="mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-lg-4 mb-4 mb-lg-0">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="flaticon-team-1"></i>
                            </div>
                            <div className="counter-content">
                                <h4>Affordable Tuition</h4>
                                <p>Highly competitive rates yet, premium academic delivery</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mb-4 mb-lg-0">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="flaticon-trophy-1"></i>
                            </div>
                            <div className="counter-content">
                                <h4>Best Among Equals</h4>
                                <p>Best learning Environment with Globally acclaimed professionals</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mb-4 mb-lg-0">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="flaticon-diploma-1"></i>
                            </div>
                            <div className="counter-content">
                                <h4>Success Driven</h4>
                                <p>The experiential learning ensures professional student success.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default CounterSection;
