import React from "react";
import WhyUs from "../../../images/facilities/why-us.jpg"
import Pattern from "../../../images/bg/pattern.png"

function FindCourseSection() {
    return (
        <section className="bg-dark">
            <div className="container-fluid">
                <div className="row no-guuters">
                    <div className="col-lg-6 bg-holder d-none d-lg-block"
                         style={{backgroundImage: `url(${WhyUs})`}}>
                    </div>
                    <div className="col-lg-6 px-4 px-md-5 px-lg-6 py-6 px-lg-6"
                         style={{backgroundImage: `url(${Pattern})`}}>
                        <div className="section-title">
                            <h2 className="title">Why Choose Us</h2>
                        </div>
                        <div className="media">
                            <i className="flaticon-knowledge mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">Multidisciplinary study programs</h5>
                                <p className="text-white mb-0">Highly competitive rates yet, premium academic delivery</p>
                            </div>
                        </div>
                        <span className="border-bottom d-block my-5 border-light"></span>
                        <div className="media">
                            <i className="flaticon-mortarboard-2 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">International study programs</h5>
                                <p className="text-white mb-0">The experiential learning ensures professional student success.</p>
                            </div>
                        </div>
                        <span className="border-bottom d-block my-5 border-light"></span>
                        <div className="media">
                            <i className="flaticon-team-1 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">Highly qualified teaching staff</h5>
                                <p className="text-white mb-0">Best learning Environment with Globally acclaimed professionals</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FindCourseSection;
