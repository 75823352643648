import React from "react";
import WhyUs from "../../../images/facilities/why-us.jpg"
import Pattern from "../../../images/bg/pattern.png"

function PartnersSection() {
    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="owl-carousel our-clients bg-white p-0" data-nav-arrow="false" data-items="5"
                             data-md-items="4" data-sm-items="3" data-xs-items="2" data-xx-items="1" data-space="0"
                             data-autoheight="true">
                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/01.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/02.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/03.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/04.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/05.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/06.svg"
                                     alt=""/>
                            </div>

                            <div className="item">
                                <img className="img-fluid center-block mx-auto grayscale" src="images/client/07.svg"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection;
