import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
  setGeneralDetails,
  setNewsDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import NewsContent from "./section/newscontent";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/03.jpg";
import SideSearch from "./section/sidebar";

function News(props) {
  const [loading, setLoading] = useState(true);
  const [newsList, setNewsList] = useState(props.newsList);

  const getData = async () => {
    await axios
      .get(`${serverLink}news/list/all/General`)
      .then((result) => {
        const data = result.data;
        setLoading(false);
        setNewsList(data);
        props.setOnNewsDetails(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData();
  }, [""]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Banner
                Image={bg3}
                Title={"News"}
                breadcrumb={["Home", "News"]}
            />

            <section className="mb-5"/>
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <NewsContent
                  newsList={newsList}
                  setGeneralDetails={props.setOnGeneralDetails}
                />
                {/* <SideSearch
                  newsList={newsList}
                  setGeneralDetails={props.setOnGeneralDetails}
                /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsList: state.NewsDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(News);
