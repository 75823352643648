import React from "react";
import President from "../../../images/facilities/president.jpeg"

function WelcomeSection() {
    return (
        <section className="space-pb mt-6">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 pr-lg-5">
                        <div className="row">
                            <div className="col-sm-12">
                                <img className="img-fluid w-100 border-radius" src={President} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0 pr-xl-6">
                        <div className="section-title mb-4">
                            <h2 className="title">Personal Education, Extraordinary Success</h2>
                            <p className="lead mb-0">We are an innovative educational institution dedicated to the creation of the competitively sound graduates</p>
                        </div>
                        <p className="mb-4 mb-lg-5">We focus our efforts on shaping socially responsible and goal oriented individuals into world-class professionals.
                            who shall endure in their goal to develop and offer service to the Nigerian nation and the world at large..</p>
                        <div className="testimonial-item">
                            <div className="testimonial-quote text-center mb-4">
                                <i className="fas fa-quote-left fa-2x text-white"></i>
                            </div>
                            <div className="testimonial-content">
                                <p>We continue to be guided by moral teachings in the Holy Books on the importance of knowledge and the duty of every human to seek knowledge.</p>
                            </div>
                            <div className="testimonial-author">
                                <div className="testimonial-name">
                                    <h5 className="name">Dr M.K Dikwa, mni FCNA</h5>
                                    <span>- ACA(President and Founder)</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default WelcomeSection;
