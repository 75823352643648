import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Banner from "../../../common/banner/banner";
import bg3 from "../../../../images/bg/03.jpg";
import axios from "axios";
import { EmailTemplates, formatDateAndTime, serverLink } from "../../../../resources/url";
import { toast } from "react-toastify";
import { sendEmail } from "../../../../resources/url";
import { Link, useNavigate } from "react-router-dom";
import { encryptData } from "../../../../resources/url";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";

const ApplicantLogin=(props)=>{
    const navigate = useNavigate();
    const [formType, setFormType] = useState('Login')
    const [login, setLogin] = useState({
      EmailAddress: "",
      Password: "",
      RecoveryEmail: ""
    });
  
    const [dateline, setDateline] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const getDateline = async () => {
      await axios
        .get(`${serverLink}application/dateline`)
        .then((res) => {
          setDateline(res.data.data[0]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("NETWORK ERROR", err);
        });
    };
  
    useEffect(() => {
      getDateline();
    }, []);
  
    useEffect(() => {
      if (props.applicationLogin !== "")
        navigate("/admission/application/dashboard");
    }, [props.applicationLogin]);
  
    const handleLogin = async (e) => {
      e.preventDefault();
      if (login.EmailAddress === "") {
        toast.error("Please enter your email address");
        return false;
      }
  
      if (login.Password === "") {
        toast.error("Please enter your password");
        return false;
      }
  
      await axios
        .post(`${serverLink}application/login`, login)
        .then((response) => {
          const data = response.data;
          if (data.message === "success") {
            toast.success("Login Successful. Please wait...");
            const dateOfBirth = data.data[0].DateOfBirth.split("T")[0];
            const sendData = {
              ...data.data[0],
              DateOfBirth: dateOfBirth,
            };
  
            setTimeout(() => {
              props.setOnApplicationLoginDetails(sendData);
            }, 2000);
          } else toast.error("Invalid login credential!");
        })
        .catch((error) => {
          toast.error("Network error. Check your connection and try again!");
        });
    };
  
    const handleForgetPassword = async (e) => {
      e.preventDefault();
      const token = "pa" + Math.floor(Math.random() * 999999999);
      const email = EmailTemplates('2', encryptData(token));
  
      await axios.patch(`${serverLink}application/forget_password/add_token/${login.RecoveryEmail}`, { token: encryptData(token) })
        .then((result) => {
          if (result.data.message === "success") {
            toast.success('An email have been sent to your mailbox, please check and proceed.')
            sendEmail(
              login.RecoveryEmail, email.subject, email.title, login.RecoveryEmail, email.body, '', ''
            )
          } else {
            // toast.error('Please try again.')
          }
        }).catch((e) => {
          console.log('NETWORK ERROR')
        })
  
    }
  
    const onEdit = (e) => {
      if (e.target.id === "EmailAddress")
        setLogin({
          ...login,
          [e.target.id]: e.target.value,
        });
      else
        setLogin({
          ...login,
          [e.target.id]: encryptData(e.target.value),
        });
  
      if (e.target.id === "RecoveryEmail") {
        setLogin({
          ...login,
          RecoveryEmail: e.target.value
        })
      }
    };

    return(
        <>
        <Banner
        Image={bg3}
        Title={"Login"}
        breadcrumb={["Admission Application", "Login"]}
        height="50px"
         />
         <section className="space-ptb">
         {formType === "Login" &&
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center">
              <div className="section-title">
                <h2>Sign In To Your Account</h2>
                <p>Sign in with email and password</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7 mb-2">
              <form className="login bg-white shadow p-5" onSubmit={handleLogin}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="email" className="form-control"  onChange={onEdit} id="EmailAddress" placeholder="Enter email" />
                  <span className="focus-border" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" className="form-control"  onChange={onEdit} id="Password" placeholder="Password" />
                  <span className="focus-border" />
                </div>
                {dateline.IsAlwaysOpen < 1 && (
                  <div className="alert alert-warning">
                    Application Dateline:{" "}
                    {formatDateAndTime(dateline.EndDate, "date")}
                  </div>
                )}
                {new Date(dateline.EndDate) > new Date() ? (
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                ) : (
                  <div className="alert alert-danger">Application Closed</div>
                )}
                <p className="mt-2 mb-0">Don't have an account? <Link  to="/admission/application/register">
                  Not registered? Register here
                </Link></p>
                <br />
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                  setFormType("ForgetPassword")
                }}>Forgot Password</span>
              </form>
            </div>
          </div>
        </div>
}

{formType === "ForgetPassword" &&
<div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center">
              <div className="section-title">
                <h2>Recover your password</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7 mb-2">
              <form className="login bg-white shadow p-5" onSubmit={handleForgetPassword}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="email" className="form-control"  onChange={onEdit} id="RecoveryEmail" placeholder="Enter email" />
                  <span className="focus-border" />
                </div>
                <br/>
                <button type="submit" className="btn btn-primary">
                  Reset Password
                </button>
<br/><br/>
                <Link to="/admission/application/register">
                  Not registered? Register here
                </Link>
                <br />
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} href="#" onClick={() => {
                  setFormType("Login")
                }}>Login</span>
              </form>
            </div>
          </div>
        </div>
}
      </section>
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantLogin);

