import React, { useState, useEffect } from "react";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";
import { serverLink } from "../../resources/url";
import axios from "axios";
import { connect } from "react-redux";
import {
    setGeneralDetails,
    setNewsDetails,
} from "../../actions/setactiondetails";
import Loader from "../common/loader/loader";
import { NavLink } from "react-router-dom";


const Events = (props) => {
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState(props.eventList.length > 0 ? props.eventList : []);
    const [eventList_1, setEventList_1] = useState(props.eventList.length > 0 ? props.eventList : []);

    const getData = async () => {
        await axios
            .get(`${serverLink}event/all/active`)
            .then((result) => {
                const data = result.data;
                setEventList_1(data);
                setEventList(data);
                props.setOnEventDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const getDateMonth = (date) => {
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const d = new Date(date);
        return monthNames[d.getMonth()];
    };

    const getDateDay = (date) => {
        const d = new Date(date);
        return d.getDay();
    };

    const getDateYear = (date) => {
        const d = new Date(date);
        return d.getFullYear();
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSearch = (e)=>{
        let filtered = eventList_1.filter(x=>x.Title.toLowerCase().includes(e.target.value.toLowerCase()));
        setEventList(filtered)
    }


    return loading ? (<Loader />) : (
        <div>
            <Banner
                Image={bg3}
                Title={"Events"}
                breadcrumb={["Home", "Events"]}
            />

            <section className="space-ptb">
                <div className="container">
                    <div className="course-archive bg-dark py-3 px-4 mb-5 border-radius">
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-md-8">
                                <div className="d-flex">
                                    <div className="mr-3">
                                        <span className="text-white" ><i className="fa fa-th-large mr-2" /></span>
                                    </div>
                                    <div className="text-white">
                                        Showing {eventList.length} events
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4 mt-md-0">
                                <form className="navbar-form search d-flex">
                                    <input type="text" name="q" className="form-control input-search rounded-0 bg-white" onChange={handleSearch} placeholder="search" autoComplete="off" />
                                    <a href="#"><i className="fa fa-search text-white" /></a>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            eventList.length > 0 ?
                                (
                                    eventList.map((item, index) => {
                                        const eventClicked = async (item) => {
                                            const EventData = { ...item };
                                            await props.setOnGeneralDetails(EventData);
                                        };
                                        return (
                                            <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                                <div className="events shadow h-100">
                                                    <div className="events-img">
                                                        <img className="img-fluid border-0" src={item.ImagePath !==null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath : `${serverLink}public/uploads/event_uploads/${item.ImagePath}` : ""} alt="" />
                                                    </div>
                                                    <div className="events-content p-4">
                                                        {/* <div className="events-tag">
                                                            <a href="#" className="badge-danger mb-2">Art Competition</a>
                                                        </div> */}
                                                        <NavLink className="text-primary h5" to={`/events/${item.Slug}`} onClick={() => eventClicked(item)} >
                                                        {item.Title}
                                                        </NavLink>
                                                        <div className="events-meta my-2">
                                                            <ul className="list-unstyled mb-0 d-flex">
                                                                <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1" /> {getDateDay(item.EventDate)} {getDateMonth(item.EventDate)}, {getDateYear(item.EventDate)}</a></li>
                                                                <li><a href="#"><i className="fas fa-clock pr-1" />  {item.StartTime} - {item.EndTime}</a></li>
                                                            </ul>
                                                        </div>
                                                        <p className="text-dark mb-0">
                                                        <span  dangerouslySetInnerHTML={{ __html: `${item.Description.substring(0,100)}...` }}/>
                                                        </p>
                                                        <NavLink className="btn btn-dark btn-round mt-3" to={`/events/${item.Slug}`} onClick={() => eventClicked(item)} >
                                                            View Event
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                )
                                :
                                ("No Events")
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Events);


//export default Events;