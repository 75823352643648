import React from "react";
import { Link } from "react-router-dom";
import { formatDateAndTime, serverLink } from "../../../../resources/url";

function SideSearch(props) {
    const bottomClicked = async (item) => {
        const newsData = { ...item };
        props.setGeneralDetails(newsData);
        props.channgeScreen(item);
    };
    return (
        <div className="">
            {props.newsList.length > 0 ? (
                <div className="blog-sidebar">
                    <div className="widget">
                        <div className="widget-title">
                            <h5>Related News</h5>
                        </div>
                        {props.newsList
                            .sort(() => Math.random() - 0.5)
                            .map((item, key) => {
                                return (
                                    <div className="media mb-3 pb-3 border-bottom" key={key}>

                                        <div className="media mb-3 pb-3 border-bottom">
                                            <Link to={`/news/${item.Slug}`}>
                                                <img className="img-fluid avatar border-radius"
                                                    src={item.ImagePath !==null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :`${serverLink}public/uploads/news_uploads/${item.ImagePath}`: ""}
                                                    onClick={() => bottomClicked(item)}
                                                    alt="Image"
                                                />
                                            </Link>
                                            <div className="media-body ml-3">
                                                <Link to={`/news/${item.Slug}`}>
                                                    <span onClick={() => bottomClicked(item)}>
                                                        {" "}
                                                        {item.Title}{" "}
                                                    </span>{" "}
                                                </Link>
                                                <div className="events-meta">
                                                    <ul className="list-unstyled mb-0 d-flex">
                                                        <li><i className="fas fa-calendar-alt text-danger"/> {formatDateAndTime(item.InsertedDate, "date") }</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default SideSearch;
