import React from "react";
import { subjects } from "../../../common/ssce/subjects";

export default function UGExamResult(props) {
    const options = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"];
    return (
        <>
            <div className="row w-100">
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor={`result_type`}>
                            Exam Type{" "}
                            {props.index < 6 && <span className="text-danger">*</span>}
                        </label>
                        <select
                            id={`result_type`}
                            data={props.index}
                            className="form-control"
                            onChange={props.handleChange}
                        >
                            <option value="">--</option>
                            {props.exam_type.ExamType1 !== "" && (
                                <option value={props.exam_type.ExamType1}>
                                    {props.exam_type.ExamType1}
                                </option>
                            )}
                            {props.exam_type.ExamType2 !== "" && (
                                <option value={props.exam_type.ExamType2}>
                                    {props.exam_type.ExamType2}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor={`result_subject`}>
                            Subject {props.index < 6 && <span className="text-danger">*</span>}
                        </label>
                        <select
                            id={`result_subject`}
                            data={props.index}
                            className="form-control"
                            onChange={props.handleChange}
                        >
                            <option value="">--</option>
                            {subjects.map((s, i) => (
                                <option key={i} value={s}>
                                    {s}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor={`result_score`}>
                            Grade {props.index < 6 && <span className="text-danger">*</span>}
                        </label>
                        <select
                            id={`result_score`}
                            data={props.index}
                            className="form-control"
                            onChange={props.handleChange}
                        >
                            <option value="">--</option>
                            {options.map((o, i) => (
                                <option key={i} value={o}>
                                    {o}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
}