import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";
import Banner from "../../common/banner/banner";
import _ from "lodash";
import {Button, Modal} from "react-bootstrap";

    const Senate = () => {
        const [senateList, setSenateList] = useState([]);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            async function getSenateList() {
                const result = await axios.get(`${serverLink}leadership/senate/list`);
                setSenateList(result.data);
                setLoading(false);
            }
            getSenateList();
        }, []);
    return (
        <>
            {loading ? <Loader /> : ""}
            <PageName page={"Senate"} />

            <Banner
                Title={"Senate"}
                breadcrumb={["Home", "Senate"]}
            />
            <div className="container mb-5 pb-3">
                <div className="col-md-12">
                    <h3 className="text-center my-5">Senate</h3>
                    <div className="d-flex align-items-center align-content-between flex-column">
                        <div className="row">
                            {senateList?.map((senate, index) => (
                                <div className=" " key={index}>
                                    <img
                                        style={{ height: 300, width: 300 }}
                                        src={senate.ImagePath !== null ? senate.ImagePath.includes("simplefileupload") ? senate.ImagePath :  `${serverLink}public/uploads/leaderships_uploads/${senate.ImagePath}` : ""}
                                        // src={
                                        //     !_.isEmpty(senate.ImagePath)
                                        //         ? `${serverLink}public/uploads/leaderships_uploads/${senate.ImagePath}`
                                        //         : `${serverLink}public/uploads/no-image.jpeg`
                                        // }
                                        alt={senate.FullName}
                                    />
                                    <div className="col-md-12">
                                        <h6 className="text-center mt-1 w-100">
                                            {senate.Title} {senate.FullName}
                                        </h6>
                                        <p className="text-center mb-5">{senate.Designation}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Senate;