import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setCourseDataDetails, setCourseListDetails } from "../../actions/setactiondetails";
import {currencyConverter, formatDateAndTime, serverLink} from "../../resources/url";
import Banner from "../common/banner/banner";
import Loader from "../common/loader/loader";
import bg3 from "../../images/bg/03.jpg";


const CourseDetails = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { slug } = useParams();
    const [designation, setDesignation] = useState([]);


    if (slug === "") navigate("/admission/application");

    const [loading, setLoading] = useState(false);
    let courseList = props.courseList.filter((item) => item.Slug === slug);
    const [courseDetail, setCourseDetail] = useState(
        courseList.length > 0
            ? props.courseDetails.filter(
                (item) => item.CourseCode === courseList[0].CourseCode
            )
            : []
    );
    useEffect(()=>{
        axios
            .get(`${serverLink}academic/designation/`)
            .then((response) => {
                setDesignation(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }, [])

    useEffect(() => {
        const slug_record =
            location.pathname.split("/")[location.pathname.split("/").length - 1];
        courseList.push(
            props.courseList.filter((item) => item.Slug === slug_record)
        );
        setCourseDetail(
            props.courseDetails.filter(
                (item) => item.CourseCode === courseList[0].CourseCode
            )
        );
    }, [location.pathname]);

    const getCourseData = async () => {
        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((response) => {
                const data = response.data;
                const course_data = data.filter((item) => item.Slug === slug);
                props.setOnCourseListDetail(response.data);
                if (data.filter((item) => item.Slug === slug).length < 1) {
                    navigate("/admission/application");
                } else {
                    courseList.push(course_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/course/details`)
            .then((response) => {
                props.setOnCourseData(response.data);
                setCourseDetail(
                    response.data.filter(
                        (item) => item.CourseCode === courseList[0].CourseCode
                    )
                );
                if (courseDetail.length === 0) navigate("/admission/application");
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        getCourseData();
    }, [courseDetail.length === 0]);
    return loading ? (
        <Loader />
    ) : (
        <>
            <Banner
                Image={courseDetail.length > 0 ? `${serverLink}public/uploads/course_uploads/${courseDetail[0].ImagePath}`: ''}
                Title={courseList[0].CourseName}
                breadcrumb={["Academics", "Course Finder"]}
                height="50px"
            />
            {
                courseDetail.length > 0 ? 
                <div>
                    <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-5 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="shadow border-radius p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                    </div>
                                                    <p style={{ textAlign: "justify", padding: "5px" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: courseDetail[0].Description,
                                                        }} />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            props.hrStaffList.length > 0 &&
                                            <div className="section-title mt-5 mb-4">
                                                <h4 className="mb-4">Lecturers</h4>
                                                <div className="course-details-lecturer bg-dark border-radius p-3 p-lg-4">
                                                    <div className="row col-md-12">
                                                        {
                                                            props.hrStaffList.filter(x => x.CourseCode === courseDetail[0].CourseCode).map((item, index) => {
                                                                return (

                                                                    <div className="col-6" key={index}>
                                                                        <ul className="pl-0 mb-0">
                                                                            <li className="d-md-flex align-items-center justify-content-between">
                                                                                <div className="lecturer-img">
                                                                                    <img src={`${serverLink}public/uploads/hr/document/${item.Image}`} className="img-fluid" alt="" />
                                                                                </div>
                                                                                <div className="">
                                                                                    <h6><Link to={`/staff/${item.Slug}`} className="text-white">{item.FirstName} {item.Surname} {item.MiddleName}</Link></h6>
                                                                                    <p className="mb-0">{
                                                                                    designation.filter((i) =>
                                                                                    i.EntryID ===
                                                                                    item.DesignationID
                                                                                )
                                                                                    .map((r) => r.DesignationName)}</p>
                                                                                </div>
                                                                                {/* <div>
                                                                                <small><a target={"_blank"} href={`mailto: ${item.OfficialEmailAddress}`}>{item.OfficialEmailAddress}</a></small>
                                                                                </div> */}
                                                                            </li>
                                                                        </ul>
                                                                        <hr className="text-white"/>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="course-sidebar shadow">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Course Details</h5>
                                    </div>
                                    <div className="widget-course">
                                        <ul className="list-unstyled list-style mb-0">
                                            <li><span>Tuition :&emsp;&emsp;&emsp;</span>
                                                <span style={{ float: "right" }}>{currencyConverter(courseList[0].TuitionFee)}
                                                </span>
                                            </li>
                                            <li><span>Code :&emsp;&emsp;&emsp;</span>
                                                <span style={{ float: "right" }}>{courseList[0].CourseCode}
                                                </span>
                                            </li>
                                            <li><span>Duration :&emsp;&emsp;&emsp;</span>
                                                <span style={{ float: "right" }}>{`${courseList[0].Duration} ${courseList[0].DurationType}`}
                                                </span>
                                            </li>
                                            <li>
                                                <span>Degree In View :&emsp;&emsp;&emsp;</span><br />
                                                <span style={{ textAlign: "center" }}>{courseList[0].DegreeInView}
                                                </span>
                                            </li>
                                            <li style={{ textAlign: "center" }}>
                                                <span style={{ textAlign: "center" }}>
                                                    Department of {courseList[0].DepartmentName}
                                                </span>
                                            </li>
                                            <li>
                                                <Link style={{ textDecoration: "underline", textAlign: "center" }}
                                                    to={`/faculty/${courseList[0].FacultyName.toLowerCase()
                                                        .trim()
                                                        .replaceAll(" ", "-")}`}
                                                >
                                                    {courseList[0].FacultyName}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Other Courses</h5>
                                    </div>
                                    <div className="widget-categories">
                                        <ul className="list-unstyled list-style mb-0">
                                            {props.courseList.filter(
                                                (item) => item.FacultyCode === courseList[0].FacultyCode
                                            ).length > 0 &&
                                                props.courseList
                                                    .filter(
                                                        (item) => item.FacultyCode === courseList[0].FacultyCode
                                                    )
                                                    .map((course, index) => {
                                                        if (course.CourseCode !== courseList[0].CourseCode) {
                                                            return (
                                                                <li key={index}>
                                                                    <Link className="d-flex" to={`/course/${course.Slug}`}>
                                                                        <span>{course.CourseName}</span>
                                                                        <i className="fas fa-chevron-right text-right ml-auto" />
                                                                    </Link>

                                                                </li>
                                                            );
                                                        }
                                                    })}
                                        </ul>
                                    </div>
                                </div>

                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Events</h5>
                                    </div>
                                    {
                                        props.eventList.filter(x => x.FacultyCode === courseList[0].FacultyCode).length > 0 ?
                                            <div>
                                                {
                                                    props.eventList.filter(x => x.FacultyCode === courseList[0].FacultyCode).map((item, index) => {
                                                        return (
                                                            <div className="events" key={index}>
                                                                <div className="media mb-3 pb-3 border-bottom">
                                                                    <img className="img-fluid avatar border-radius" src={`${serverLink}public/uploads/event_uploads/${item.ImagePath}`} alt="" />
                                                                    <div className="media-body ml-3">
                                                                        <a className="d-block date text-dark" href="#">
                                                                            {formatDateAndTime(item.EventDate, "date")}
                                                                        </a>
                                                                        <Link className="text-dark" to={`/events/${item.Slug}`}>
                                                                            <b>{item.Title}</b>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className="alert alert-info"><b>No Events</b></div>
                                    }

                                </div>

                                <div className="widget">
                                    <div className="widget-categories">
                                        <Link to={"/apply-now"} className="btn btn-dark w-100">
                                            Apply Now
                                        </Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
                </div>
                :
                <div></div>
            }
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
        courseDetails: state.CourseDataDetails,
        departmentList: state.DepartmentListDetails,
        eventList: state.EventDetails,
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetail: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseData: (p) => {
            dispatch(setCourseDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
