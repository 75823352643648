import React, {useState} from "react";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function FindCourseSection(props) {
    const navigate = useNavigate();
    const [selectedCourse, setSelectedCourse] = useState('1');

    const handleCourseChange = (e) => {
      setSelectedCourse(e.target.value) 
      if(e.target.value !== '1'){
        navigate(e.target.value)
      }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedCourse !== '1') {
            navigate(selectedCourse)
        } else {
            toast.warn('Please select a course first');
        }
    }

    return (
        <section className="mt-md-n5 mt-4 position-relative z-index-9">
            <div className="container">
                <form onSubmit={onSubmit} className="course-form border-radius">
                    <div className="row">
                        <div className="col-md-8 mb-4 mb-md-0">
                            <div className="form-group select-border mb-0">
                                <label>Your Courses</label>
                                <select className="form-control basic-select" onChange={handleCourseChange}>
                                    <option value="1">Please Choose</option>
                                    {
                                        props.courses.length > 0 &&
                                            props.courses.map((course, index) => {
                                                return <option key={index} value={`/course/${course.Slug}`}>{course.CourseName}</option>
                                            })
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center">
                            <div className="course-serach">
                                <button className="btn btn-dark btn-block mt-md-4" type="submit">Search Course</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        courses: state.CourseListDetails
    };
};

export default connect(mapStateToProps, null)(FindCourseSection);
