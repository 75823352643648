import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";
import Banner from "../../common/banner/banner";
import LeadershipProfileModal from "./profile-modal/modal";
import ProfilePage from "./profile-modal/profile-page";
import {Link} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";

const Board = () => {
    const [boards, setBoard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    const handleCloseApplicationModal = () => setShowApplicationModal(false);
    const handleShowApplicationModal = () => setShowApplicationModal(true);

    useEffect(() => {
        async function getBoardMemebers() {
            const result = await axios.get(`${serverLink}leadership/board/list`);
            setBoard(result.data);
            setLoading(false);
        }
        getBoardMemebers();
    });
    const [boardmember, setMember] = useState({})
    return (
        <>
            {loading ? <Loader /> : ""}
            <PageName page={"Board Members"} />

            <Banner
                Title={"Board Members"}
                breadcrumb={["Home", "Board Members"]}
            />
            <div className="container mb-5 pb-3">
                <div className="col-12">
                    <h3 className="text-center my-5">Board Members</h3>
                    <div className="d-flex align-items-center align-content-between flex-column">
                        <div className="row">
                            {boards.map((board, index) => (
                                <div
                                    className={`text-center ${
                                        index === 0 ? "col-md-12 d-block" : "col-md-4"
                                    }  `}
                                    key={index}
                                >
                                    <img
                                        src={board.ImagePath !== null ? board.ImagePath.includes("simplefileupload") ? board.ImagePath :  `${serverLink}public/uploads/leaderships_uploads/${board.ImagePath}` : ""}
                                        alt={board.FullName}
                                        className="img-thumbnail"
                                        style={{
                                            height: 400,
                                            width: 400,
                                        }}
                                    />
                                    <h4 className="my-2">
                                        {board.Title} {board.FullName}
                                    </h4>
                                    <h5 className="text-center mb-2">{board.Designation}</h5>
                                    <p style={{ cursor: 'pointer'}} className="text-center mb-5" data-bs-toggle="modal" data-bs-target="#pModal" onClick={() => {
                                        handleShowApplicationModal();
                                        setMember(board)
                                    }}>View profile</p>
                                </div>
                            ))}
                        </div>
                        <Modal
                            show={showApplicationModal}
                            onHide={handleCloseApplicationModal}
                            size="lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title> {boardmember.Title} {boardmember.FullName}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="" style={{textAlign:'justify' }}>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: boardmember.Description,
                                        }}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={handleCloseApplicationModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Board;