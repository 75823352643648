import React from "react";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";

const AcademicCalender = (props) => {


    return (
        <>
            <Banner
                Image={bg3}
                Title={"Academic Calender"}
                breadcrumb={["Academics", "Academic Calender"]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-4">Al-Ansar University runs three semesters per academic year. Students typically register minimum of 16 credit units and maximum of 26 credit units per semester. A credit unit typically includes lectures, interactive sessions, laboratory/workshops, seminars and field trips.</p>
                            
                            <table className="table table-responsive-lg table-hover">
                                <thead>
                                    <tr>
                                        <td><h5>Event</h5></td>
                                        <td><h5>Date</h5></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Opening of Admission Portal and Registration</td>
                                        <td>6th Sept 2022 - 12 Oct 2022</td>
                                    </tr>
                                    <tr>
                                        <td>Enrollment and Registration of New Students</td>
                                        <td>20th Oct 2022 - 11th Jan 2023</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AcademicCalender;