import React from "react";
import {connect} from "react-redux";
import {formatDateAndTime, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
import Logo from "../../../images/logo.png"


function LatestNewsSection(props) {
    return (
        <section className="space-ptb position-relative">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10 text-center">

                        <div className="section-title">
                            <h2 className="title">Latest Articles</h2>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-0">

                        {
                            props.news.length > 0 &&
                            <div className="blog mb-4 mb-lg-0">

                                <div className="blog-image">
                                    <img className="img-fluid border-radius" 
                                    src={
                                        props.news[0].ImagePath !== null ?
                                        props.news[0].ImagePath.includes('simplefileupload.com') ? props.news[0].ImagePath : `${serverLink}public/uploads/news_uploads/${props.news[0].ImagePath}` : Logo
                                    } 
                                    alt={props.news[0].Title}/>
                                </div>

                                <div className="blog-content pt-4 border-0">
                                    <h5 className="blog-title mb-3"><Link to={`/news/${props.news[0].Slug}`}>{props.news[0].Title}</Link></h5>
                                    <div className="blog-meta mb-0">
                                        <ul className="list-unstyled mb-0 d-flex">
                                            <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"/> {formatDateAndTime(props.news[0].InsertedDate, 'date_and_time')}</a></li>
                                        </ul>
                                    </div>
                                    <p className="mt-3 mb-0" dangerouslySetInnerHTML={{__html: props.news[0].Description.substring(0, 200)}} />
                                </div>
                            </div>
                        }



                    </div>
                    <div className="col-lg-6 col-md-12 mb-0">

                        {
                            props.news.length > 0 &&
                                props.news.sort((a, b) => b.InsertedDate - a.InsertedDate).slice(0,4).map((n, i) => {
                                    if (i > 0 && i < 5) {
                                        const img = n.ImagePath !== null ? n.ImagePath.includes('simplefileupload.com') ? n.ImagePath : `${serverLink}public/uploads/news_uploads/${n.ImagePath}`: Logo;

                                        return <div key={i} className={`blog d-md-flex ${i !== 4 && 'border-bottom mb-4 pb-4'}`}>
                                            <div className="blog-image mr-0 mr-sm-3">
                                                <img className="img-fluid border-radius" src={img} alt={n.Title}/>
                                            </div>

                                            <div className="blog-content pt-4 pt-md-0 border-0">
                                                <h6 className="blog-title mb-3"><Link to={`/news/${n.Slug}`}>{n.Title}</Link></h6>
                                                <div className="blog-meta mb-0">
                                                    <ul className="list-unstyled mb-0 d-flex">
                                                        <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"/>{formatDateAndTime(n.InsertedDate, 'date_and_time')}</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                })
                        }

                    </div>
                    <Link className="btn btn-primary" to={'/news'}>Read more...</Link>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        news: state.NewsDetails
    };
};

export default connect(mapStateToProps, null)(LatestNewsSection);
